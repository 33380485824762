import { Link } from "react-scroll";
import React, { useState } from "react";
import logo from "../assets/logo-flat.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="fixed top-0 w-full z-50  bg-opacity-90 py-4 bg-black">
      <div className="container mx-auto flex items-center justify-between md:justify-center space-x-16 lg:space-x-32 xl:space-x-64 px-4 md:px-8">
        {/* Logo */}
        <Link
          to="home"
          smooth={true}
          duration={500}
          className={`cursor-pointer`}
        >
          <div className="text-gold text-2xl font-bold flex justify-start items-center">
            <img src={logo} className={`h-8`} />
            <span className={`ml-2`}>Pinoy Papa</span>
          </div>
        </Link>

        {/* Desktop Navigation */}
        <ul className="hidden md:flex space-x-12 lg:space-x-16">
          <li>
            <Link
              to="home"
              smooth={true}
              duration={500}
              className="text-white hover:text-gold cursor-pointer"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="mission"
              smooth={true}
              duration={500}
              className="text-white hover:text-gold cursor-pointer"
            >
              Mission
            </Link>
          </li>
          {/*<li>*/}
          {/*  <Link*/}
          {/*    to="features"*/}
          {/*    smooth={true}*/}
          {/*    duration={500}*/}
          {/*    className="text-white hover:text-gold cursor-pointer"*/}
          {/*  >*/}
          {/*    Features*/}
          {/*  </Link>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <Link*/}
          {/*    to="pricing"*/}
          {/*    smooth={true}*/}
          {/*    duration={500}*/}
          {/*    className="text-white hover:text-gold cursor-pointer"*/}
          {/*  >*/}
          {/*    Pricing*/}
          {/*  </Link>*/}
          {/*</li>*/}
          <li>
            <Link
              to="vision"
              smooth={true}
              duration={500}
              className="text-white hover:text-gold cursor-pointer"
            >
              Vision
            </Link>
          </li>
          {/*<li>*/}
          {/*  <Link*/}
          {/*    to="contact"*/}
          {/*    smooth={true}*/}
          {/*    duration={500}*/}
          {/*    className="text-white hover:text-gold cursor-pointer"*/}
          {/*  >*/}
          {/*    Contact*/}
          {/*  </Link>*/}
          {/*</li>*/}
        </ul>

        {/* Sign Up button for desktop */}
        <div className="hidden md:block">
          <button className="bg-green text-white py-2 px-4 rounded hover:bg-gold hover:text-black transition duration-300">
            Sign Up
          </button>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button onClick={() => setIsOpen(!isOpen)}>
            <svg
              className="text-white w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-red bg-opacity-90">
          <ul className="flex flex-col items-center py-4 space-y-4">
            <li>
              <Link
                to="home"
                smooth={true}
                duration={500}
                className="text-white hover:text-gold cursor-pointer"
                onClick={() => setIsOpen(false)}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="mission"
                smooth={true}
                duration={500}
                className="text-white hover:text-gold cursor-pointer"
                onClick={() => setIsOpen(false)}
              >
                Mission
              </Link>
            </li>
            {/*<li>*/}
            {/*  <Link*/}
            {/*    to="features"*/}
            {/*    smooth={true}*/}
            {/*    duration={500}*/}
            {/*    className="text-white hover:text-gold cursor-pointer"*/}
            {/*    onClick={() => setIsOpen(false)}*/}
            {/*  >*/}
            {/*    Features*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link*/}
            {/*    to="pricing"*/}
            {/*    smooth={true}*/}
            {/*    duration={500}*/}
            {/*    className="text-white hover:text-gold cursor-pointer"*/}
            {/*    onClick={() => setIsOpen(false)}*/}
            {/*  >*/}
            {/*    Pricing*/}
            {/*  </Link>*/}
            {/*</li>*/}
            <li>
              <Link
                to="vision"
                smooth={true}
                duration={500}
                className="text-white hover:text-gold cursor-pointer"
                onClick={() => setIsOpen(false)}
              >
                Vision
              </Link>
            </li>
            {/*<li>*/}
            {/*  <Link*/}
            {/*    to="contact"*/}
            {/*    smooth={true}*/}
            {/*    duration={500}*/}
            {/*    className="text-white hover:text-gold cursor-pointer"*/}
            {/*    onClick={() => setIsOpen(false)}*/}
            {/*  >*/}
            {/*    Contact*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <button className="bg-green text-white py-2 px-4 rounded hover:bg-gold hover:text-black transition duration-300">*/}
            {/*    Sign Up*/}
            {/*  </button>*/}
            {/*</li>*/}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
