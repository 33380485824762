import { Element } from "react-scroll";
import React from "react";

const Home = () => {
  return (
    <Element name="home" className="section">
      {/* Three.js Animation Background */}
      <div className="relative h-screen w-full bg-black">
        {/*<HomeBackground />*/}
        <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center text-white px-4 text-center z-10">
          <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold">
            Welcome to PinoyPapa
          </h1>
          {/*<p className="text-lg sm:text-xl md:text-2xl mt-4">*/}
          {/*  Fast, secure, and easy payments for all your needs.*/}
          {/*</p>*/}
        </div>
      </div>
    </Element>
  );
};
export default Home;
