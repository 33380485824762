import { Element } from "react-scroll";
import React from "react";

const Vision = () => {
  return (
    <Element name="vision" className="section">
      <div className="h-screen bg-black flex flex-col justify-center items-center px-4 text-center">
        <h2 className="text-white text-5xl font-bold mb-6">Vision</h2>
        <p className="text-xl text-white mb-4 w-3/5">
          To establish pinoypapa.com as the most dedicated virtual network, and
          as a devoted partner to each and every individual Filipino worker and
          their family, both local and international.
        </p>
        {/*<div className="w-full max-w-4xl h-96">/!*<ThreeScene />*!/</div>*/}
      </div>
    </Element>
  );
};
export default Vision;
