import { Element } from "react-scroll";
import React from "react";

const Mission = () => {
  return (
    <Element name="mission" className="section">
      <div className="h-screen bg-black flex flex-col justify-center items-center px-4 text-center">
        <h2 className="text-white text-5xl font-bold mb-6">Mission</h2>
        <p className="text-xl text-white mb-4 w-3/5">
          To empower each and every individual Filipino worker, both local and
          international, through their hard-earned money. By providing
          accessibility to a comprehensive and dependable online financial
          services system, representing as a support channel to each of their
          family.
        </p>
        {/*<div className="w-full max-w-4xl h-96">/!*<ThreeScene />*!/</div>*/}
      </div>
    </Element>
  );
};
export default Mission;
