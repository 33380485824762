import React from "react";
import Home from "./sections/home";
import Navbar from "./components/navbar";
import Mission from "./sections/mission";
import Vision from "./sections/vision";

const App = () => {
  return (
    <div className="font-poppins">
      <Navbar />
      <Home />
      <Mission />
      <Vision />
      {/*<Features />*/}
      {/*<Pricing />*/}
      {/*<Contact />*/}
    </div>
  );
};

export default App;
